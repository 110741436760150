<template>
  <div class="nav-header">
    <div class="container">
      <!-- logo -->
      <div class="n-logo">
        <h4>四川天府惠民社区健康科技有限公司 </h4>
        <!-- <img src="/imgs/logo.png" alt="" /> -->
        <!-- <h2>谜语网络</h2> -->
      </div>
      <!-- 右侧 -->
      <div class="n-r">
        <!-- 导航 -->
        <div class="n-list">
          <a href="/" class="active">网站首页</a>
          <!-- <a href="/#/info">关于我们</a> -->
          <a href="/#/ac1">药品信息</a>
          <!-- <a href="/#/ac2">会员中心</a> -->
          <a href="/#/ac3">医药资讯</a>
          <a class="n-login" @click="goLogin()" v-show="this.LoggedShow"> 登录 / 注册</a>
          <!-- <a class="n-login" @click="goRegister()" v-show="this.LoggedShow">  </a> -->

          <!-- 用户名 -->
          <a class="n-info" v-show="!this.LoggedShow" v-text="this.nv_user.Username"><i
              class="iconfont icon-denglu"></i></a>

          <!-- 退出登录 -->
          <a class="n-info" @click="tolg()" v-show="!this.LoggedShow">
            <i class="iconfont icon-tuichudenglu"></i>
            退出登录
          </a>
        </div>
        <!-- 联系方式 -->
        <!-- <div class="n-phone">
          <i class="iconfont icon-weibiaoti-"></i>
          ******
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "nav-header",
  props: {
    href: String,
  },
  data() {
    return {
      // 按钮显示/隐藏
      LoggedShow: true,
      // 获取到 user 用户信息
      nv_user: JSON.parse(window.localStorage.getItem("user")),
      // 存储状态
      state: JSON.parse(window.localStorage.getItem("state")),
    };
  },
  methods: {
    // 登录时隐藏逻辑
    logged() {
      if (this.state == "0") {
        this.LoggedShow = false;
      }
    },
    // 登录跳转
    goLogin() {
      this.$router.push("/login");
    },
    // 注册跳转
    goRegister() {
      this.$router.push("/register");
    },
    // 退出登录
    tolg() {
      window.sessionStorage.removeItem('user')
      window.localStorage.setItem("state", "10");
      this.LoggedShow = true;
      this.$router.push("/");
      location.reload();
    },
    // 关于我们跳转
    goUs() {
      this.$router.push("/ac2");
    },
    goSc() {
      this.$router.push("/ac1");
    },
    // 新闻跳转
    goInfo() {
      this.$router.push("/ac3");
    },
    goCtm() {
      this.$router.push("/ac4");
    },
  },
  mounted() {
    this.logged();
  }
};
</script>

<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";

.nav-header {
  width: 100%;
  height: 80px;
  background-color: #fff;

  .container {
    width: 1226px;
    height: 100%;
    @include flex();

    // logo
    .n-logo {
      width: 250px;
      height: 100%;
      @include flex();

      img {
        width: 70%;
        height: 100%;
      }

      h2 {
        font-size: 16px;
        color: #ff430b;
        cursor: pointer;
      }

      h4 {
        font-size: 14px;
        font-weight: bold;
      }
    }

    // 右侧
    .n-r {
      @include flex();
      height: 100%;
      flex: 1;
      margin-left: 30px;

      // 导航
      .n-list {
        padding-left: 40px;
        box-sizing: border-box;

        .active {
          color: #00bfb0;
        }

        a {
          font-size: 12px;
          color: #666;
          margin: 0 30px;
          transition: all 0.2s;
          text-decoration: none;
          cursor: pointer;

          &:hover {
            color: #00bfb0;
          }
        }
      }

      // 联系方式
      .n-phone {
        font-size: 14px;
        color: #666;
        width: 150px;
        // transition: all .3s;
        cursor: pointer;

        &:hover {
          font-weight: bold;
          font-size: 15px;
          color: #00bfb0;
        }

        i {
          font-size: 18px;
        }
      }
    }
  }
}
</style>
