<template>
  <div class="index">
    <!-- 上层 -->
    <div class="s-x">
      <div class="my-container">
        <div class="l">
          <h2>{{ this.title }}</h2>
        </div>
        <div class="r">
          <h4>当前位置：</h4>
          <a href="/">首页</a> <span>/</span> <a href="javascript:;">药品详情</a>
        </div>
      </div>
    </div>

    <!-- 下层-->
    <div class="s-con">
      <div class="my-container">
        <img :src="this.img" alt="" style="width: 400px;margin-bottom: 25px;" />
        <img :src="this.img2" alt="" />
        <!-- <img :src="this.img3" alt="" /> -->
        <!-- <img :src="this.img3" alt=""  v-show="!this.nl" />
        <img :src="this.img4" alt=""  v-if="this.img4"  v-show="!this.nl" />
         -->
        <!-- 产看更多 -->
        <!-- <a href="javascript:;" class="yc-btn" @click="mpay()" v-show="this.acs">VIP阅读全文 <span>></span></a> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      nv_user: "",
      nv_user2: JSON.parse(window.sessionStorage.getItem("user")),
      state: JSON.parse(window.localStorage.getItem("state")),
      href: "index.html",
      // 获取 id
      myId: this.$route.query.id,
      // 获取 标题
      title: "",

      // 图片路径
      img: "",
      img2: "",
      img3: "",
      img4: "",
      nl: false,
      acs: true,
    };
  },
  components: {},
  mounted() {

    var user = window.sessionStorage.getItem('user');
    console.log(this.nv_user2)
    if (user) {
      this.nv_user = JSON.parse(user);
      if (this.nv_user.Username == "mxw") {
        this.init2()
      }

      if (this.nv_user.Username != "mxw") this.init();
    } else {
      this.init();
    }

    console.log(this.acs)
    document.title = "北京自我药疗科技传播中心";
  },
  methods: {
    init() {
      this.axios.get("/mock/yp.json").then((res) => {
        var vl = res.data;
        var date = vl.find((vl) => vl.id == this.myId);
        this.title = date.title;
        this.img = date.img;
        this.img2 = date.img2;
        this.img3 = date.imgs;
        this.img4 = date.imgs2;
        if (this.state !== 0) {
          this.nl = true;
        } else {
          this.nl = false;
        }
      });
    },
    init2() {

      this.axios.get("/mock/yp.json").then((res) => {
        var vl = res.data;
        var date = vl.find((vl) => vl.id == this.myId);
        this.title = date.title;
        this.img = date.img;
        this.img2 = date.img2;
        this.img3 = date.imgs;
        this.img4 = date.imgs2;
        if (this.state !== 0) {
          this.nl = true;
        } else {
          this.nl = false;
        }

        this.acs = false
      });
    },
    // 跳转功能
    goDays(methodsWords, id) {
      this[methodsWords](id);
    },
    // 跳转详情页
    goDetails(id) {
      this.$router.push({
        path: "/sound/:" + id,
        query: { type: id },
      });
      location.reload();
    },
    // 收费逻辑
    gopay() {
      // console.log('1')

      if (this.state == 0) {
        // this.$router.push("/pay");
        this.showModal = true;
        console.log(this.showModal);
      }
      // 未登录
      if (this.state !== 0) {
        alert("资讯每条3元，点击确定注册购买");
        this.$router.push({ path: "/register", query: { k: 1 } });
        return;
      }
    },
    // 跳转会员
    mpay() {
      if (this.state == 0) {
        // this.$router.push("/pay");
        // this.showModal = true;
        console.log(this.showModal);
        this.$router.push("/ac2");
      }
      // 未登录
      if (this.state !== 0) {
        // alert("资讯每条3元，点击确定注册购买");
        // this.$router.push({ path: "/login", query: { k: 1 } });
        this.$router.push("/login");
        return;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";

.index {
  .s-x {
    margin: 30px 0;
    width: 100%;
    padding: 10px 0;
    border-bottom: 1px dashed #ccc;
    box-sizing: border-box;

    .my-container {
      height: 100%;
      @include flex();

      .l {
        h2 {
          font-size: 14px;
          color: #333;
          font-weight: 400;
        }
      }

      .r {
        display: flex;
        align-items: center;

        h4 {
          font-size: 14px;
          color: #333;
        }

        a {
          font-size: 12px;
          color: #333;
          cursor: pointer;
          text-decoration: none;
        }

        span {
          font-size: 12px;
          color: #888;
          margin: 0 8px;
        }
      }
    }
  }

  .s-con {
    margin: 60px 0;

    .my-container {
      // width: 900px;
      height: auto;
      position: relative;

      img {
        width: 100%;
        height: 100%;
      }

      // 按钮
      .yc-btn {
        display: block;
        overflow: hidden;
        width: 150px;
        height: 44px;
        margin: 10px auto;
        cursor: pointer;
        text-align: center;
        line-height: 44px;
        color: #fff;
        border: 1px solid #fff;
        font-size: 14px;
        transition: all 0.5s;
        text-decoration: none;
        position: absolute;
        bottom: -5%;
        left: 48%;
        background-color: $colorZ;

        // font-weight: bold;
        &:hover {
          background-color: $colorZ;
          border: 1px solid $colorZ;
        }

        span {
          margin-left: 8px;
        }
      }
    }
  }
}
</style>
