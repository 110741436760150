<template>
  <div class="index">
    <div class="banner">
      <div class="container">
        <img src="/imgs/01.jpg" alt="" />
      </div>
    </div>
    <!-- 资讯区块2 -->
    <div class="a2">
      <div class="container">
        <!-- 右侧 -->
        <div class="a2-r">
          <!-- 标题 -->
          <div class="r-title">
            <h2>医药资讯</h2>
          </div>

          <!-- 循环项 -->
          <div class="r-item" v-for="(item, index) in this.mdata" :key="index" @click="goDetails(item.id)">
            <!-- 右侧信息 -->
            <div class="item-info">
              <div class="blink"></div>
              <span><i class="iconfont icon-zuozhe"></i> {{ item.author }}</span>
              <span><i class="iconfont icon-time"></i>{{ item.time }}</span>
              <span> <i class="iconfont icon-dianzan"></i> {{ item.sub }} </span>
              <!-- 标题 -->
              <h2>{{ item.title }}</h2>
              <!-- 简介 -->
              <p>
                {{ item.jj }}
              </p>
            </div>

            <!-- 左侧图片 -->
            <div class="item-img">
              <img :src="item.img" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      mdata: [],
      data2: [],
      showModal: false,
      state: JSON.parse(window.localStorage.getItem("state")),
    };
  },

  mounted() {
    document.title = "四川天府惠民社区健康科技有限公司";
    this.init();
    this.init2();
  },
  methods: {
    
    init() {
      this.axios.get("/mock/ac1.json").then((res) => {
        const data = res.data;
        this.mdata = data.splice(0, 6);
      });
    },

    // 跳转 逻辑 封装
    goDays(methods, id) {
      this[methods](id);
    },
    // 收费逻辑
    gopay() {
      this.showModal = true;
    },
    // 跳转详情页
    goDetails(id) {
      this.$router.push({
        path: "/sound/:" + id,
        query: { type: id },
      });
    },
    msub() {
      // alert("支付失败！");
      this.$message.error("支付失败!");
      this.showModal = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";

.index {
  .banner {
    width: 100%;
    height: 154px;
    margin: 30px 0;

    img {
      width: 100%;
      height: 100%;
    }
  }

  // 资讯区块2
  .a2 {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: space-between;
    margin: 60px 0;

    .container {

      // 右侧
      .a2-r {
        width: 100%;
        height: 100%;

        // background-color: pink;
        .r-title {
          width: 100%;
          height: 50px;
          border-left: 3px solid $colorZ;
          padding-left: 30px;
          box-sizing: border-box;
          border-bottom: 1px dashed #ccc;

          h2 {
            line-height: 50px;
            font-weight: 400;
            font-size: 18px;
            color: #333;
          }
        }

        // 循环项
        .r-item {
          width: 100%;
          height: 160px;
          // background: #c60023;
          margin: 20px 0;
          display: flex;
          align-items: center;
          border-bottom: 2px dashed #ccc;
          transition: all 0.2s;
          // border-top: 2px solid #fff;
          cursor: pointer;
          border-top-width: 1;

          &:hover {
            background-color: #eee;

            .item-info {
              h2 {
                color: #333;
              }

              .blink {
                width: 100%;
              }

              p {
                font-size: 12px;
                width: 100%;
                text-overflow: -o-ellipsis-lastline;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                line-clamp: 2;
                -webkit-box-orient: vertical;
              }
            }
          }

          .item-img {
            width: 300px;
            height: 100%;

            img {
              width: 100%;
              height: 100%;
            }
          }

          .item-info {
            margin-right: 30px;
            width: 70%;
            transition: all 0.4s;
            height: 100%;
            padding-top: 35px;
            box-sizing: border-box;
            position: relative;
            padding-left: 20px;

            .blink {
              transition: all 0.4s;
              width: 0px;
              height: 2px;
              background-color: #00bfb0;
              position: absolute;
              top: 0;
              left: 0;
            }

            span {
              font-size: 12px;
              margin-right: 15px;
              color: #888;

              i {
                font-weight: 400;
                margin-right: 5px;
                font-size: 14px;
              }
            }

            h2 {
              font-size: 16px;
              color: #333;
              margin: 10px auto;
              transition: all 0.2s;
            }

            p {
              font-size: 12px;
              color: #888;
              line-height: 30px;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 3;
              overflow: hidden;
            }
          }
        }
      }
    }
  }
}</style>
