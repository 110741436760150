<template>
  <div>

    <div class="main-bg">
      <!-- title -->
      <h1>四川天府惠民社区健康科技有限公司</h1>
      <!-- //title -->
      <div class="sub-main-w3">
        <div class="image-style">

        </div>
        <!-- vertical tabs -->
        <div class="vertical-tab">
          <!-- 登录 -->
          <div id="section1" class="section-w3ls">
            <input type="radio" name="sections" id="option1" checked>
            <label for="option1" class="icon-left-w3pvt"><span class="fa fa-user-circle"
                aria-hidden="true"></span>登录</label>
            <article>
              <form action="#" method="post">
                <h3 class="legend">账号登录</h3>
                <div class="input">
                  <span class="fa fa-envelope-o" aria-hidden="true"></span>
                  <input type="email" placeholder="用户名" id="user_name" v-model="lUsername" required />
                </div>
                <div class="input">
                  <span class="fa fa-key" aria-hidden="true"></span>
                  <input type="password" placeholder="密码" id="password" v-model="lPassword" required />
                </div>
                <button type="submit" class="btn submit" @click="logo()">登 录</button>

              </form>
            </article>
          </div>

          <!-- 注册 -->
          <div id="section2" class="section-w3ls">
            <input type="radio" name="sections" id="option2">
            <label for="option2" class="icon-left-w3pvt"><span class="fa fa-pencil-square"
                aria-hidden="true"></span>注册</label>
            <article>
              <form action="#" method="post">
                <h3 class="legend">注册帐户</h3>
                <div class="input">
                  <span class="fa fa-user-o" aria-hidden="true"></span>
                  <input type="text" id="r_user_name" v-model="rUsername" placeholder="用户名" name="name" required />
                </div>
                <div class="input">
                  <span class="fa fa-key" aria-hidden="true"></span>
                  <input type="password" placeholder="密码" id="r_password" v-model="rPassword" name="password" required />
                </div>

                <button type="submit" class="btn submit" @click="register()">注 册</button>

              </form>
            </article>
          </div>

          <!-- 重置密码 -->
          <div id="section3" class="section-w3ls">
            <input type="radio" name="sections" id="option3">
            <label for="option3" class="icon-left-w3pvt"><span class="fa fa-lock" aria-hidden="true"></span>忘记密码?</label>
            <article>
              <form action="#" method="post">
                <h3 class="legend last">重置密码</h3>
                <p class="para-style">请在下面输入您的电子邮件地址，我们将给您发送一封带有说明的电子邮件。</p>
                <p class="para-style-2"><strong>需要帮助?</strong>了解更多关于如何 </p>
                <div class="input">
                  <span class="fa fa-envelope-o" aria-hidden="true"></span>
                  <input type="email" v-model="forgetdata.email" placeholder="邮箱" name="email" required />
                </div>
                <a href="javascript:;" type="submit" class="btn submit last-btn" @click="abc()">提交</a>
              </form>
            </article>
          </div>
        </div>
        <!-- //vertical tabs -->
        <div class="clear"></div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      cur: 0, //默认选中第一个tab
      // 用户名
      lUsername: "",
      // 密码
      lPassword: "",
      // 获取收费跳转参数
      k: this.$route.query.k,
      // 获取到 user 用户信息
      logo_user: JSON.parse(window.localStorage.getItem("user")),
      href: "index.html",
      rUsername: "",
      rPassword: "",
      nowYear: new Date().getFullYear(),
      logindata: {
        email: "123@foxmail.com",
        password: "admin123"
      },
      registerdata: {
        name: "123@foxmail.com",
        password: "admin123",
        confirmPassword: "admin123"
      },
      forgetdata: {
        email: "xxxxxx@xx.com",
      }
    };
  },
  components: {
  },
  mounted() {
    document.title = "四川天府惠民社区健康科技有限公司";
  },
  methods: {
    // 登录逻辑
    logo() {
      // 预设用户
      var custom_user = {
        Username: "mxw",
        Password: "mxw",
        rPhone: "123",
      };

      // 如果是预设用户
      if (this.lUsername == "mxw") {
        if (this.lPassword == "mxw") {
          window.sessionStorage.setItem("user", JSON.stringify(custom_user));
        } else {
          alert("密码错误");
          return;
        }
      }

      // 不是预设用户
      if (this.lUsername != "mxw") {
        custom_user = {
          Username: this.lUsername,
          Password: this.lPassword,
          rPhone: "123",
        };
        window.sessionStorage.setItem("user", JSON.stringify(custom_user));
      }
      window.localStorage.setItem("state", "0");
      this.$router.push("/");
      window.location.reload();

      // if (this.lUsername !== this.logo_user.Username) {
      //     alert("用户名错误");
      //     return;
      //   } else if (this.lPassword !== this.logo_user.Password) {
      //     alert("密码错误");
      //     return;
      //   } else  {
      //     window.localStorage.setItem("state", "0");
      //     this.$router.push("/");
      //     window.location.reload();
      //   }
    },
    goReg() {
      this.$router.push("/register");
    },
    // 注册逻辑
    register() {
      // 获取 user
      var ruser = {
        Username: this.rUsername,
        Password: this.rPassword,
      };
      // 判断非空
      if (!this.rUsername) {
        alert("请输入用户名");
        return;
      }
      if (!this.rPassword) {
        alert("请输入密码");
        return;
      }
      window.localStorage.setItem("state", "0");
      window.localStorage.setItem("user", JSON.stringify(ruser));
      this.$router.push("/#/index");
    },
    // 跳转注册协议
    goAg() {
      this.$router.push("/jf");
    },
    goAg2() {
      this.$router.push("/zw");
    },
    abc() {
      alert('提交成功')
    }
  },
};
</script>

<style lang="scss" scoped>
/* reset */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
sub,
sup,
tt,
var,
b,
u,
i,
dl,
dt,
dd,
ol,
nav ul,
nav li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
copyright,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
copyright,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

ol,
ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* start editing from here */
a {
  text-decoration: none;
}

.txt-rt {
  text-align: right;
}

/* text align right */
.txt-lt {
  text-align: left;
}

/* text align left */
.txt-center {
  text-align: center;
}

/* text align center */
.float-rt {
  float: right;
}

/* float right */
.float-lt {
  float: left;
}

/* float left */
.clear {
  clear: both;
}

/* clear float */
.pos-relative {
  position: relative;
}

/* Position Relative */
.pos-absolute {
  position: absolute;
}

/* Position Absolute */
.vertical-base {
  vertical-align: baseline;
}

/* vertical align baseline */
.vertical-top {
  vertical-align: top;
}

/* vertical align top */
nav.vertical ul li {
  display: block;
}

/* vertical menu */
nav.horizontal ul li {
  display: inline-block;
}

/* horizontal menu */
img {
  max-width: 100%;
}

/* //end reset */

body {
  font-size: 100%;
  font-family: 'Roboto', sans-serif;
}

.main-bg {
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("https://picsum.photos/1920/1080");
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  -ms-background-size: cover;
  min-height: 100vh;
  background-color: #262626;
}

/* title */
h1 {
  font-size: 3vw;
  color: #fff;
  text-align: center;
  padding: 2.5vw 1vw 1vw;
  letter-spacing: 3px;
  text-transform: uppercase;
  font-weight: 600;
}

/* //title */

.sub-main-w3 {
  margin: 1.5vw 16vw;
  margin: 5vw auto;
  max-width: 1067px;
}

.image-style {
  background: url(/images/m.jpg) no-repeat center;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  -ms-background-size: cover;
  min-height: 371px;
}

/* vertical tabs */
.vertical-tab {
  color: #333;
  display: block;
  margin: auto;
  position: relative;
  float: left;
  /* width: 70%; */
}

.image-style {
  float: left;
  width: 25%;
}

.vertical-tab input[name="sections"] {
  left: -9999px;
  position: absolute;
  top: -9999px;
}

.vertical-tab .section-w3ls {
  display: block;
}

.vertical-tab .section-w3ls label {
  background: #fff;
  border-right: 1px solid #dcdcdc;
  cursor: pointer;
  display: block;
  font-size: 17px;
  padding: 33px 20px;
  position: relative;
  width: 189px;
  z-index: 100;
  color: #111;
  text-align: center;
}

.vertical-tab .section-w3ls article {
  display: none;
  left: 230px;
  min-width: 570px;
  position: absolute;
  top: 0;
}

/* .vertical-tab .section-w3ls article:after {
    background-color: #fff;
    bottom: 0;
    content: "";
    display: block;
    left: -229px;
    position: absolute;
    top: 0;
    width: 220px;
    z-index: 1;
} */

.vertical-tab input[name="sections"]:checked+label {
  background: #00ad45;
  border-right: 1px solid #000;
  color: #fff;
}

.vertical-tab input[name="sections"]:checked~article {
  display: block;
}

label.icon-left-w3pvt span {
  display: block;
  font-size: 30px;
  margin-bottom: 7.8px;
}

/* //vertical tabs */

/* form style */
.sub-main-w3 form {
  background: #ffff;
  padding: 2em;
}

.legend {
  color: #000;
  font-size: 24px;
  text-align: center;
  margin-bottom: 1.2em;
  font-weight: 400;
}

p.para-style {
  margin-bottom: .8em;
  font-size: 15px;
  letter-spacing: .5px;
  line-height: 1.8;
}

p.para-style-2 {
  font-size: 15px;
  letter-spacing: .5px;
  line-height: 1.8;
  margin-bottom: 2em;
}

p.para-style-2 a {
  color: blue;
}

.input {
  margin-bottom: 1em;
  padding: 13px 15px;
  border: 1px solid #eee;
  background: #eee;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.input span {
  font-size: 15px;
  color: #464646;
  margin-right: 10px;
}

.input input {
  color: #000;
  font-size: 14px;
  letter-spacing: 1px;
  box-sizing: border-box;
  border: none;
  outline: none;
  background: transparent;
  width: 100%;
}

.submit {
  margin: 2.3em auto 0;
  background: #00ad45;
  border: none;
  cursor: pointer;
  font-weight: 600;
  -webkit-transition: 0.5s all;
  -o-transition: 0.5s all;
  -moz-transition: 0.5s all;
  -ms-transition: 0.5s all;
  transition: 0.5s all;
  display: block;
  color: #fff;
  padding: 14px 30px;
  -webkit-border-radius: 0;
  -o-border-radius: 0;
  -ms-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  font-size: 15px;
  letter-spacing: 2px;
  width: 100%;
}

.submit:hover {
  opacity: .8;
  -webkit-transition: 0.5s all;
  -o-transition: 0.5s all;
  -moz-transition: 0.5s all;
  -ms-transition: 0.5s all;
  transition: 0.5s all;
}

a.bottom-text-w3ls {
  color: #757474;
  font-size: 14px;
  display: inherit;
  letter-spacing: 1px;
  text-align: center;
  margin-top: 3.2em;
}

/* //form style */

/* copyright */
.copyright {
  margin-top: 9vw;
  padding-bottom: 1.5vw;
}

.copyright h2 {
  font-size: 15px;
  color: #fff;
  letter-spacing: 1px;
  text-align: center;
  line-height: 1.8;
}

.copyright h2 a {
  color: #fff;
  font-weight: bold;
  -webkit-transition: 0.5s all;
  -o-transition: 0.5s all;
  -moz-transition: 0.5s all;
  -ms-transition: 0.5s all;
  transition: 0.5s all;
}

.copyright h2 a:hover {
  opacity: .8;
  -webkit-transition: 0.5s all;
  -o-transition: 0.5s all;
  -moz-transition: 0.5s all;
  -ms-transition: 0.5s all;
  transition: 0.5s all;
}

/* //copyright */

/* responsive */
@media(max-width: 1080px) {
  h1 {
    font-size: 4vw;
  }
}

@media(max-width: 1050px) {
  .sub-main-w3 {
    max-width: 900px;
  }

  .vertical-tab .section-w3ls article {
    min-width: 445px;
  }

  p.para-style-2 {
    font-size: 14px;
    margin-bottom: 1em;
  }

  p.para-style {
    margin-bottom: .7em;
    font-size: 14px;
  }
}

@media(max-width: 900px) {
  .sub-main-w3 {
    max-width: 768px;
  }

  .vertical-tab .section-w3ls article {
    min-width: 400px;
    left: 175px;
  }

  .vertical-tab .section-w3ls label {
    width: 134px;
    font-size: 15px;
    padding: 34px 20px;
  }

  h1 {
    font-size: 5vw;
    padding: 4.5vw 1vw 1vw;
  }
}

@media(max-width: 768px) {
  .sub-main-w3 {
    max-width: 575px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .image-style {
    float: none;
    width: 100%;
    min-height: 300px;
    order: 2;
  }

  .vertical-tab {
    float: none;
    width: 100%;
  }
}

@media(max-width: 667px) {
  h1 {
    font-size: 6vw;
  }
}

@media(max-width: 640px) {
  .copyright h2 {
    font-size: 14px;
  }
}

@media(max-width: 600px) {
  .sub-main-w3 {
    max-width: 500px;
  }

  .vertical-tab .section-w3ls article {
    min-width: 365px;
    left: 135px;
  }

  .vertical-tab .section-w3ls label {
    width: 114px;
    font-size: 15px;
    padding: 31px 10px;
  }

  h1 {
    font-size: 7vw;
  }
}

@media(max-width: 480px) {
  .vertical-tab .section-w3ls article {
    min-width: 291px;
    left: 109px;
  }

  .vertical-tab .section-w3ls label {
    width: 89px;
    font-size: 14px;
    padding: 26.8px 10px;
  }

  .sub-main-w3 {
    max-width: 400px;
  }

  .image-style {
    min-height: 220px;
  }

  .sub-main-w3 form {
    padding: 1.5em;
  }

  .submit {
    font-size: 14px;
  }

  .input input {
    font-size: 13px;
  }

  p.para-style {
    margin-bottom: .4em;
    font-size: 13px;
  }

  p.para-style-2 {
    font-size: 13px;
  }

  .input {
    padding: 12px 12px;
  }

  a.bottom-text-w3ls {
    margin-top: 3em;
  }

  .last-btn {
    margin: 1em auto 0;
  }

  .copyright h2 {
    font-size: 12px;
  }
}

@media(max-width: 414px) {
  .sub-main-w3 {
    max-width: 320px;
  }

  .vertical-tab .section-w3ls article {
    position: static;
  }

  .vertical-tab .section-w3ls label {
    width: 100%;
    font-size: 14px;
    padding: 20px 0;
    border-right: none;
    border-bottom: 1px solid #dcdcdc;
    border-top: 1px solid #dcdcdc;
  }

  .vertical-tab input[name="sections"]:checked+label {
    border-right: none;
    border-bottom: 1px solid #000;
    border-top: none;
  }

  .image-style {
    min-height: 190px;
  }

  h1 {
    font-size: 1.8em;
    padding: 6.5vw 1vw 3vw;
  }

  .copyright h2 {
    font-size: 13px;
  }

  .last-btn {
    margin: 1.5em auto 0;
  }

  p.para-style {
    margin-bottom: 1em;
  }

  p.para-style-2 {
    margin-bottom: 2em;
  }

}

@media(max-width: 320px) {
  .sub-main-w3 {
    max-width: 270px;
  }

  .vertical-tab .section-w3ls article {
    min-width: 270px;
  }
}

/* //responsive */
</style>
