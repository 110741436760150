<template>
  <div class="ac2" style="width: 100%">
    <!-- banner -->
    <div class="banner" style="width: 100%">
      <img src="/imgs/ct.jpg" style="width: 100%" />
    </div>
    <!-- 顶宽布局 -->
    <div class="container">
      <!-- 标题 -->
      <div class="a2-title">
        <h3>关于我们</h3>
        <div class="blink"></div>
      </div>
      <!-- 容器 -->
      <div class="a2-con">
        <div class="a2-l">
          <div class="a2-box">
            <p>
              北京御笙堂中医门诊部有限公司成立于2017年11月23日，注册地位于北京市昌平区城北街道西关路20号2号楼1层102-01室
            </p>
            <p>
              经营范围包括医疗服务；销售食品；零售药品；销售第三类医疗器械；销售（含网上销售）医疗器械一类、二类、日用品、化妆品、电子产品、五金交电（不含电动自行车）、机械设备、针纺织品、化工产品（不含危险化学品）；承办展览展示；设计、制作、代理、发布广告；医学研究与试验发展；健康管理、健康咨询（须经审批的诊疗活动除外）；技术推广、技术转让、技术开发、技术服务、技术咨询；企业管理；经济信息咨询（不含中介服务）；
            </p>
            <p>
              企业策划、设计；技术进出口、代理进出口、货物进出口；租赁建筑工程机械设备；出租商业用房、出租办公用房。（市场主体依法自主选择经营项目，开展经营活动；医疗服务、销售食品、零售药品、销售第三类医疗器械以及依法须经批准的项目，经相关部门批准后依批准的内容开展经营活动；不得从事国家和本市产业政策禁止和限制类项目的经营活动。）
            </p>
          </div>
        </div>
        <div class="a2-r"></div>
      </div>
      <!-- 文本区 -->
      <div class="a2-info">
        <!-- 价值观 -->
        <div class="a2-item">
          <h3>联系我们:</h3>
          <p>公司名称：北京御笙堂中医门诊部有限公司</p>
          <!-- <p>公司电话：18670356814</p>
          <p>公司邮箱：luopeng@aishangroup.com</p> -->
          <p>网站域名：yushengtang123.com</p>
          <p>公司地址：北京市昌平区城北街道西关路20号2号楼1层102-01室</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "swiper/css/swiper.css";

export default {
  name: "ac1",
  components: {},
  data() {
    return {};
  },
  mounted() {
    document.title = "北京御笙堂中医门诊部有限公司";
  },
};
</script>

<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";

.ac2 {
  .banner {
    width: 100%;
    height: auto;
    margin-bottom: 30px;
  }

  .container {
    // height: 1000px;
    margin: 60px auto;
    padding-top: 30px;
    box-sizing: border-box;

    // 标题
    .a2-title {
      text-align: center;

      h3 {
        font-size: 21px;
        color: #00bfb0;
      }

      .blink {
        width: 250px;
        height: 1px;
        background-color: #00bfb0;
        margin: 5px auto;
      }
    }

    // 容器
    .a2-con {
      width: 100%;
      height: 500px;
      //   background-color: pink;
      box-sizing: border-box;
      @include flex();

      // 左侧
      .a2-l {
        width: 50%;
        height: 100%;
        // background-color: red;
        position: relative;

        .a2-box {
          width: 80%;
          height: 80%;
          background-color: #4b80b7;
          position: absolute;
          right: 0;
          top: 50px;
          color: #fff;
          padding: 20px;
          box-sizing: border-box;

          p {
            font-size: 13px;
            margin: 30px auto;
          }
        }
      }

      // 右侧
      .a2-r {
        width: 50%;
        height: 100%;
        // background-color: yellow;
        background: url("/imgs/us.jpg") no-repeat center;
        background-size: cover;
      }
    }

    // 文本区
    .a2-info {
      margin-top: 30px;

      .a2-item {

        // margin-bottom: 60px;
        h3 {
          font-size: 24px;
          margin-bottom: 10px;
        }

        p {
          font-size: 14px;
          margin: 10px auto;
        }
      }
    }
  }
}</style>
